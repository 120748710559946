@import "../../../Styles/master";

#contact {
  .contact-title {
    @include title;
    border-bottom: 4px solid $themeGreen;
  }

  .contact-contact {
    font-family: $paragraphFont;
    font-weight: bold;
    background-color: $black;
    color: $white;

    .contact-contactTitle {
      text-align: center;
      font-size: 3rem;
      color: $green;
      padding: 2rem;
      background-color: $black;

      .contact-contactSubtitle {
        padding-top: 1rem;
        color: $white;
        font-size: $subtitleFontSize;
      }
    }
  }

  .contact-mapText {
    background-color: $themeBlue;
    color: $white;
    font-family: $paragraphFont;
    font-weight: bold;
    padding: 2rem;
    text-align: center;
    font-size: 2.5rem;

    .contact-mapSubtext {
      padding-top: 0.5rem;
      font-size: $subtitleFontSize;
    }

    @include responsive(tablet) {
      font-size: 2rem;

      .contact-mapSubtext {
        font-size: 1.25rem;
      }
    }
  }

  .contact-address {
    white-space: pre;
  }
}
