@import "../../../Styles/master";

.links-footerDesktop,
.links-desktop,
.links-footerSeparator,
.links-subMenuIcon {
  display: none;
}

nav {
  @include flex-center;
  @include full-width;
}

.links {
  .links-linkContainer {
    @include flex-center;
    @include full-width;

    .links-link {
      @include flex-center;
      @include full-width;
      height: $link-height;
      font-size: 1.25rem;
      letter-spacing: 1.25px;
      font-weight: bold;
      font-family: $linkFont;
      text-decoration: none;
      color: $inactiveLink;
      transition: color 0.25s linear;
      box-sizing: border-box;
      @include outline($black, 0px, 3px);

      &.links-link-active,
      &:hover {
        @include outline($black, 0px, 3px);
        color: $themeGreen;
      }

      .links-subMenuIcon {
        font-size: 2rem;
      }
    }
  }

  &.links-mobile:not(.links-footer) {
    width: 100%;

    .links-container {
      box-shadow: 0 2px 6px 2px $black;
      border-bottom: 1px solid $black;
      background-image: url("../../../Assets/Images/Backgrounds/shinglesRow.jpg");
      background-size: auto 100%;
      width: 100%;

      &:first-child {
        margin-top: -2px;
      }

      @for $i from 0 through 5 {
        &:nth-child(#{$i}) {
          z-index: $NavZ - $i;
          background-position: (180px - ($i * 60px)) center;
        }
      }
    }

    .links-linkContainer {
      width: 100%;

      .links-linkAndIcon {
        width: 100%;
      }

      .links-link {
        color: $white;
        width: 100%;
        @include outline($black, 1px, 3px);

        &.links-link-active,
        &:hover {
          @include outline($black, 1px, 2px);
          color: $themeGreen;
        }
      }
    }
  }
}

@mixin desktopStyles {
  display: flex;
  justify-content: center;
  flex-basis: 100%;

  .links-linkContainer {
    @include inline-center;
    flex-basis: auto;
    border-top: none;
    width: auto;
    position: relative;
    height: 100%;

    .links-link {
      padding-left: 1.5rem;
      padding-right: 1.5rem;
      min-width: 6rem;
      border-top: none;
    }
  }
}

.links-footerMobile {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  width: 100%;

  display: flex;
  flex-wrap: wrap;

  .links-outerContainer {
    display: flex;
    flex-basis: auto;
  }

  .links-linkContainer {
    border-top: none;
    display: inline-flex !important;
    flex-basis: auto !important;
    width: auto !important;
    display: flex;
    flex-wrap: nowrap;

    .links-linkAndIcon {
      @include inline-center;

      @include responsive(desktop) {
        .links-footerSeparator {
          display: block !important;
          height: $link-height - 1.2rem;
          padding-right: 0.1rem;
          border-right: 3px solid #666666;
        }
      }
    }

    .links-link {
      // color: $footerLinkColor !important;
      padding-top: 0.5rem;
      padding-bottom: 0.5rem;
      padding-right: 0;
      height: auto;
      box-sizing: border-box;
      font-size: 1rem;
      width: 100%;

      @include responsive(desktopLarge) {
        font-size: 1.25rem;
      }

      .link-text {
        box-sizing: border-box;
        padding-left: 1rem !important;
        padding-right: 1rem !important;

        @include responsive(tablet) {
          padding-left: 1rem !important;
          padding-right: 1rem !important;
        }

        @include responsive(desktopLarge) {
          padding-left: 1rem !important;
          padding-right: 1rem !important;
        }

        @include responsive(widescreen) {
          padding-left: 2rem !important;
          padding-right: 2rem !important;
        }
      }

      &:hover,
      &.links-link-active {
        color: $activeLink !important;
      }
    }

    @include responsive(tablet) {
      flex-basis: 33% !important;

      .links-link {
        font-size: 1.5rem !important;
        padding-left: 0 !important;
        padding-right: 0 !important;
      }
    }

    @include responsive(tabletSmall) {
      flex-basis: 50% !important;
    }

    @include responsive(mobile) {
      flex-basis: 100% !important;

      .links-link {
        padding: 0.25rem !important;
      }
    }
  }

  .links-sublist {
    display: inline-flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    width: auto !important;
  }
}

@include responsive(stickyNavCutoff) {
  .links-stickyMobile {
    display: none;
  }

  .links-stickyDesktop {
    @include desktopStyles;

    .links-link {
      @include responsive(notWidescreen) {
        min-width: 6rem !important;
        padding-left: 0.75rem !important;
        padding-right: 0.75rem !important;
        font-size: 1.1rem !important;
      }
    }
  }
}

@include responsive(topNavCutoff) {
  .links-topMobile {
    display: none;
  }

  .links-topDesktop {
    @include desktopStyles;
  }
}
