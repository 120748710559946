@import "../../../Styles/master";

$introDuration: 1s;
$introDelay: 1s;
$windTilt: 40deg;
$startingHeight: 25%;
$heightStep: 3%;

@keyframes logoFade {
  0% {
    opacity: 0;
  }

  80% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

@keyframes wind {
  0% {
    top: $startingHeight;
    left: 100%;
    opacity: 1;
  }

  20% {
    top: $startingHeight - $heightStep;
    transform: rotate(calc($windTilt / 8));
  }

  40% {
    top: $startingHeight - 2 * $heightStep;
  }

  60% {
    top: $startingHeight - 3 * $heightStep;
    opacity: 1;
    transform: rotate(calc($windTilt / 4));
  }

  80% {
    top: $startingHeight - 4 * $heightStep - 5%;
    opacity: 1;
    transform: rotate(calc($windTilt / 2));
  }

  90% {
    top: $startingHeight - 5 * $heightStep - 15%;
    transform: rotate($windTilt);
  }

  100% {
    top: $startingHeight - 6 * $heightStep -23%;
    left: 10%;
    opacity: 0;
  }
}

.separator {
  margin-top: -0.1rem;
  padding: 0;
}

#top {
  flex-wrap: wrap;
  position: absolute;
  width: 100%;
  box-sizing: border-box;
  overflow: hidden;
  background-color: $navBackground;
  box-shadow: $navShadow;
  z-index: $NavZ;

  .top-logoAndInfo {
    display: flex;
    align-items: stretch;
    width: 100%;
    box-sizing: border-box;
    background-color: $navBackground;

    @include responsive(widescreen) {
      padding: 0.25rem;
    }

    @include responsive(topNavCutoff) {
      height: 11rem;
    }

    .top-contactInfo {
      align-items: stretch;
      flex-wrap: wrap;
      flex-direction: column;
      @include flex-center;

      .top-contactMethod {
        align-items: center;
        justify-content: flex-end !important;
        flex-grow: 1;
        width: 100%;
        padding-right: 1rem;
        box-sizing: border-box;
        font-size: 0.8rem;
        letter-spacing: 0.75px;

        @include responsive(desktopLargeSmaller) {
          padding-right: 0.5rem;
        }

        @include responsive(mobile) {
          padding-right: 0.25rem;
        }

        .top-contactTextWidescreen {
          display: none;

          @include responsive(widescreen) {
            display: inline;
            white-space: pre;
          }
        }

        &.top-contactEmail {
          .top-contactText {
            @include responsive(widescreen) {
              display: none;
            }
          }
        }

        .top-contactText {
          position: absolute;
          right: 0.5rem;
          min-width: 7rem;
          transition: all 0.4s linear;
          text-align: right;
          white-space: nowrap;
          @include outline($black, 1px, 2px);

          @include responsive(desktop) {
            right: 3.75rem;
          }

          @include responsive(desktopLargeSmaller) {
            display: none;
          }
        }

        .top-contactLink {
          min-width: 2rem;
          text-decoration: none;

          @include responsive(widescreen) {
            min-width: 3rem;
          }
        }
      }
    }

    .top-mapInfo,
    .top-contactInfo {
      display: flex;
      align-items: center;
      justify-content: center;
      flex-basis: 15%;
      margin: 0;
      transition: color 0.4s linear;
      color: $infoTextColor;
      font-family: $standardFont;
      font-size: 0.7rem;
      font-weight: bold;
      text-align: right;
      flex-direction: column;
      word-break: break-word !important;

      @include responsive(widescreen) {
        flex-basis: 25%;
        font-size: 0.8rem;
      }

      @include responsive(desktop) {
        flex-basis: 20%;
      }

      .top-mapText {
        transition: all 0.4s linear;
        overflow-wrap: anywhere;
        white-space: pre-line;

        @include responsive(desktopLargeSmaller) {
          display: none;
        }
      }

      .top-mapTextMobile,
      .top-mapText {
        width: 100%;
        padding: 0.1rem;
        box-sizing: border-box;
        font-size: 0.8rem;
        text-align: center;
        letter-spacing: 0.75px;
        white-space: pre-line;

        @include responsive(desktopLarge) {
          padding-left: 0.5rem;

          &:hover.top-mapText {
            display: block;
          }

          &.top-mapTextDesktop {
            display: block !important;
          }

          &.top-mapTextMobile {
            display: none !important;
          }
        }

        @include responsive(desktopLargeSmaller) {
          &.top-mapTextMobile {
            display: block !important;
          }
        }
      }

      .top-mapTextDesktop,
      .top-mapTextMobile {
        display: none;
        padding-top: 0.25rem;
        transition: all 0.4s linear;
        color: $infoTextColor;

        @include responsive(desktopLarge) {
          position: absolute;
          top: 100%;
        }

        @include responsive(desktopLargeSmaller) {
          padding-bottom: 0.5rem;
        }
      }

      .top-mapLink {
        align-items: center;
        justify-content: center;
        flex-wrap: wrap;
        position: relative;
      }

      .top-mapLink,
      .top-contactMethod {
        display: flex;
        justify-content: center;
        width: 100%;
        text-decoration: none;

        &:hover:not(.top-contactMethod),
        .top-contactLink:hover {
          #top-mapIcon,
          .top-contactIcon {
            color: $infoIconColorActive !important;
          }
        }

        #top-mapIcon,
        .top-contactIcon {
          display: flex;
          align-items: center;
          justify-content: center;
          box-sizing: border-box;
          transition: all;
          color: $infoIconColor;
          font-size: 2.5rem;
          transition-timing-function: linear;
          transition-duration: 0.4s;

          @include responsive(desktopLargeSmaller) {
            font-size: 2rem;
          }

          @include responsive(desktop) {
            height: 3rem;

            &:not(.top-contactIcon) {
              height: auto;
              padding-top: 1rem;
              font-size: 3.25rem;

              @include responsive(desktopLargeSmaller) {
                height: auto;
              }

              &:hover:not(.top-contactIcon) {
                font-size: 3.5rem;
              }
            }
          }
        }
      }
    }

    .top-logoLink {
      flex-basis: 70%;
      position: relative;
      overflow: hidden;
      @include inline-center;
      @include responsive(desktop) {
        flex-basis: 60%;
      }

      .top-wind {
        position: absolute;
        top: 10%;
        left: 100%;
        height: 1rem;
        width: auto;
        animation-name: wind;
        animation-duration: $introDuration;
        animation-timing-function: ease-in;

        @include responsive(tabletSmall) {
          margin-bottom: -2rem;
          animation-duration: 0.5s;
        }

        @include responsive(mobile) {
          margin-bottom: -2rem;
          animation-duration: 0.35s;
        }

        @for $i from 1 through 5 {
          &:nth-child(#{$i}) {
            animation-delay: $introDelay + (0.1s + (random(5) * 0.01s)) * $i;
          }
        }
      }

      .top-logo {
        max-height: 10rem;
        max-width: 100%;
        padding: 0.5rem;
        box-sizing: border-box;
        object-fit: contain;

        @include responsive(tablet) {
          padding: 1rem;
        }

        &.top-logoOverlay {
          position: absolute;
          opacity: 0;
          animation-name: logoFade;
          animation-duration: 3.5s;
          animation-fill-mode: forwards;
        }
      }
    }
  }

  .top-menu {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    padding-top: 0.25rem;
    padding-bottom: 0.25rem;
    box-sizing: border-box;
    background-color: $navMenuBackground;
    color: $white;

    @include responsive(topNavCutoff) {
      display: none;
    }

    &:hover {
      cursor: pointer;
    }
  }

  .top-linksContainer {
    width: 100%;
    overflow: hidden;

    @include responsive(stickyNavCutoff) {
      padding-top: 0.25rem;
      border-top: 4px solid $themeGreen;
    }

    .top-linksAnimation {
      flex-basis: 100%;
      background-color: $black;
      @include flex-center;
    }
  }
}
