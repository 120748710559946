$black: #111111;
$charcoalBlue: #121820;
$darkGray: #2b2b2b;
$redOnGold: #6d0909;
$red: #ed1c24;
$fadedRed: #be4347;
$orange: #e55715;
$brass: #a48240;
$yellow: #ecd26f;
$savannaGreen: #c4d183;
$grassGreen: #7aa837;
$greenOnGold: #275f24;
$green: #58bd7f;
$blueGray: #558967;
$seaFoam: #93c599;
$teal: #13a89e;
$blue: #40c8f4;
$blueprint: #093554;
$purple: #765aa7;
$pink: #ec008c;
$navyGray: #5d685d;
$gray: #c0c0c0;
$offWhite: #f5f5f5;
$white: #ffffff;

$themeBlue: #4b8ecb;
$themeGreen: #2db24a;

// Nav
$navBackground: $black;
$navText: $themeGreen;
$navIcons: $themeBlue;
$navLinksBackground: $themeGreen;
$navLinks: $white;
$infoTextColor: $themeGreen;
$infoIconColorActive: $yellow;
$infoIconColor: $themeBlue;
$navShadow: 0 2px 4px 0 black;
$navMenuBackground: $themeGreen;

// footer
$footerMain: $themeGreen;
$footerBorderColor: $black;
$footerBorder: 3px solid $footerBorderColor;
$footerText: $white;
$logoShadow: 0px 3px 8px 0 $black;
$footerBorder: 4px solid $themeBlue;

// Links
$linkBorderMobile: 2px solid $themeBlue;
$inactiveLink: $white;
$activeLink: $themeGreen;
$footerLinkColor: $white;
$desktopBorder: 5px ridge $themeGreen;
$mobileBorder: 6px ridge $themeGreen;

// Titles
$titleBorder: 4px solid $black;

// Button
$buttonBackground: $themeBlue;
$buttonText: $black;
$buttonShadow: $black;

// Separator
$separatorBorder: 4px dashed $white;

// BAM
$bamText: $themeBlue;

// Images
$imageText: $themeGreen;

// Services
$servicesBorderColor: $darkGray;
