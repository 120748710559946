@import "../../../Styles/master";

#services {
  font-family: $paragraphFont;
  font-weight: bold;
  background-color: $black;

  .services-title {
    @include title;
    border: none;
  }

  .services-services {
    @include flex-center;
    align-items: stretch;
    box-sizing: border-box;
    flex-wrap: wrap;
    background-color: $black;
    color: $white;
    position: relative;

    @include responsive(widescreenSmaller) {
      padding-left: 30%;
      padding-right: 10%;
    }

    @include responsive(tabletSmall) {
      padding-left: 15%;
      padding-right: 1rem;
    }

    @include responsive(mobile) {
      padding-left: 3rem;
      padding-right: 0.25rem;
    }

    .services-border {
      position: absolute;
      animation-delay: 0.25s;

      @include responsive(widescreenSmaller) {
        display: none;
      }
    }

    .services-borderHorizontal {
      border-bottom: 2px solid $servicesBorderColor;
      width: 100%;
      top: calc(50%);
    }

    .services-borderVertical {
      border-right: 2px solid $servicesBorderColor;
      height: 100%;
      left: calc(50%);
    }

    .services-service {
      @include flex-center;
      justify-content: flex-start;
      align-items: flex-end;
      flex-basis: 100%;
      min-height: 6rem;
      padding-top: 2rem;
      padding-bottom: 2rem;
      box-sizing: border-box;
      $delay: 0.25s;

      @for $i from 1 through 4 {
        &:nth-child(#{$i}) {
          animation-delay: $delay + (0.2s * $i) - 0.2;
          $delay: $delay + 0.2s;
          animation-duration: 0s;

          .services-serviceTitleContainer {
            animation-delay: $delay;
          }

          .services-item {
            @for $j from 1 through 4 {
              &:nth-child(#{$j}) {
                animation-delay: $delay + 0.25s * $i + (0.05s * $j) - 0.05s;
                $delay: $delay + 0.05s;
              }
            }
          }
        }
      }

      @include responsive(widescreen) {
        flex-basis: 50%;
        padding-left: 10%;
      }

      @include responsive(widescreenSmaller) {
        position: relative;

        &:not(:nth-child(4)):after {
          position: absolute;
          content: "";
          flex-basis: 100%;
          width: 150vw;
          bottom: 0rem;
          left: -50vw;
          border-bottom: 2px solid $servicesBorderColor;
        }
      }

      $titleWidth: 10rem;

      .services-serviceTitleContainer {
        flex-basis: $titleWidth;
        width: $titleWidth;
        height: $titleWidth;
        background-image: url("../../../Assets/Graphics/separatorGreen.png");
        background-size: 100% 100%;
        position: relative;

        @include responsive(desktopLarge) {
          $titleWidth: 11rem;
          flex-basis: $titleWidth;
          width: $titleWidth;
          height: $titleWidth;
        }

        .services-serviceTitle {
          position: absolute;
          bottom: 0.25rem;
          right: 0.25rem;
          font-size: 1.75rem;

          @include responsive(desktopLargeSmaller) {
            font-size: 1.5rem;
          }
        }
      }
      .services-serviceItemsList {
        display: flex;
        align-items: flex-end;
        flex-wrap: wrap;
        margin: 0;
        padding: 0;
        padding-right: 1rem;
        padding-left: 1rem;
        list-style: none;
        overflow: hidden;
        box-sizing: border-box;
        font-size: 1.25rem;

        flex-basis: calc(100% - 11rem);
        @include responsive(desktopLargeSmaller) {
          flex-basis: calc(100% - 10rem);
        }

        .services-item {
          flex-basis: 100%;
          box-sizing: border-box;
          padding-bottom: 0.5rem;
          padding-top: 0.5rem;
          display: block;
          word-break: break-word;

          &:last-child {
            padding-bottom: 0;
          }

          .services-itemSublistContainer {
            padding-top: 0.25rem;

            .services-itemSublist {
              padding-right: 0.5rem;
              white-space: nowrap;
              font-size: 1rem;

              @include responsive(desktop) {
                padding-left: 0.5rem;
                display: block;
              }
            }
          }
        }
      }
    }
  }

  .services-brandsContainer {
    padding-top: 3rem;
    padding-bottom: 3rem;
    background-color: $themeBlue;
    color: $white;

    .services-brandsTitle {
      @include text-width;
      @include outline($black, 0px, 1px);
      text-align: center;
      padding-bottom: 1rem;
      font-size: 2rem;
    }

    .services-brandsList {
      padding-left: 15% !important;
      padding-right: 2rem;
      display: flex;
      flex-wrap: wrap;
      margin: 0;
      padding: 0;
      box-sizing: border-box;

      @include responsive(tablet) {
        padding-left: 10% !important;
      }

      @include responsive(mobile) {
        padding-left: 20% !important;
      }

      @include responsive(desktopLarge) {
        padding-left: 10% !important;
      }

      .services-brandsListItem {
        font-size: 1.75rem;
        list-style: none;
        display: inline-block;
        padding: 1rem;
        padding-left: 2.5rem;
        position: relative;
        overflow: hidden;
        box-sizing: border-box;
        flex-basis: 50%;

        $brandDelay: 0.25s;

        @for $i from 1 through 11 {
          &:nth-child(#{$i}) {
            animation-delay: $brandDelay + ($i - 1) * 0.1s;
            $brandDelay: $brandDelay + 0.1s;
          }
        }

        @include responsive(desktopLarge) {
          flex-basis: 33%;
        }

        @include responsive(thiccc) {
          flex-basis: 25%;
        }

        @include responsive(mobile) {
          flex-basis: 100%;
        }

        @for $i from 1 through 9 {
          &:nth-child(#{$i}) {
            animation-delay: 0.2s * ($i - 1);
          }
        }

        &:before {
          content: "";
          position: absolute;
          left: 0;
          top: calc(50% - 0.75rem);
          width: 1.5rem;
          height: 1.5rem;
          background-image: url("../../../Assets/Graphics/separatorGreen.png");
          background-size: calc(1.5rem - 2px) calc(1.5rem);
          background-repeat: no-repeat;
          background-position: bottom left;
          border-right: 2px solid $black;
        }
      }
    }
  }

  .services-contactContainer {
    text-align: center;

    .services-contactTitle {
      color: $themeGreen;
      font-size: 2rem;
      padding-top: 3rem;
    }
  }
}
