@import "../../../Styles/master";

@keyframes grow-width {
  0% {
    opacity: 1;
    width: 0%;
  }

  100% {
    opacity: 1;
    width: 100%;
  }
}

#about {
  .about-title {
    @include title;
  }

  .about-overview {
    font-family: $paragraphFont;
    font-weight: bold;
    text-align: center;
    background-color: $black;
    color: $white;

    .about-overviewTagline {
      @include outline($black, 0px, 1px);
      text-align: center;
      font-size: 3rem;
      color: $black;
      padding-top: 6rem;
      background-color: $themeBlue;

      @include responsive(tabletSmall) {
        font-size: 2.5rem;
      }

      .about-contactTaglineText {
        @include flex-center;
        display: inline-flex;
        white-space: pre;
        position: relative;
      }

      .about-overviewRoof {
        position: absolute;
        bottom: -0.1rem;
        width: 150%;
      }
    }

    .about-overviewText {
      @include outline($black, 0px, 1px);
      @include text-width;
      background-color: $black;
      padding-top: 2rem;
      padding-bottom: 2rem;
      text-align: center;
      font-size: $paragraphFontSize;

      @include responsive(desktopLargeSmaller) {
        .about-break {
          height: 1rem;
          display: block;
        }

        br {
          display: none;
        }
      }
    }

    .about-services {
      .about-servicesOverview {
        @include text-width;
        background-color: $themeGreen;
        font-size: $paragraphFontSize;
        padding-top: 3rem;
        padding-bottom: 3rem;

        .aboutServicesLarge {
          font-size: 2rem;
        }
      }

      .about-servicesTitle {
        padding-top: 3rem;
        font-size: 2rem;
      }

      .about-servicesList {
        @include text-width;
        margin: 0;
        padding: 0;
        padding-top: 2rem;
        padding-bottom: 3rem;

        .about-service {
          font-size: $paragraphFontSize;
          list-style: none;
          display: inline-block;
          padding: 0.5rem;
          padding-left: 2rem;
          position: relative;

          @for $i from 1 through 9 {
            &:nth-child(#{$i}) {
              animation-delay: 0.2s * ($i - 1);
            }
          }

          &.about-serviceHidden {
            visibility: hidden;
          }

          &:before {
            content: "";
            position: absolute;
            left: 0;
            top: calc(50% - 0.75rem);
            width: 1.5rem;
            height: 1.5rem;
            border-right: 2px solid $themeBlue;
            background-image: url("../../../Assets/Graphics/separatorGreen.png");
            background-size: calc(100% - 2px) 100%;
            background-repeat: no-repeat;
          }
        }
      }
    }

    .about-specialists {
      @include text-width;
      font-weight: bold;
      font-size: 2.75rem;
      background-color: $themeBlue;
      padding-top: 2rem;

      .about-specialistsTitle {
        @include outline($black, 1px, 3px);
        font-size: 3.5rem;
        color: $themeGreen;
        padding-bottom: 2rem;
      }

      .about-specialistsSubtitle {
        @include outline($black, 0px, 1px);
        font-size: 2.5rem;
        color: $black;
        padding-bottom: 2rem;
      }
    }
  }
}

.about-break {
  @include responsive(tablet) {
    display: none;
  }
}
