@import "./Colors.scss";
@import "./Fonts.scss";
@import "./Sizing.scss";

@mixin center-content {
  justify-content: center;
  align-items: center;
}

@mixin flex-center {
  display: flex;
  @include center-content;
}

@mixin full-width {
  width: 100%;
  flex-basis: 100%;
}

@mixin inline-center {
  display: inline-flex;
  @include center-content;
}

@mixin outline($color, $size, $blur) {
  text-shadow: -$size (-$size) $blur $color, $size (-$size) $blur $color,
    -$size $size $blur $color, $size $size $blur $color;
}

@mixin button {
  border-radius: $borderRadius;
  box-shadow: 1px 1px 3px 0px $buttonShadow;
  background-color: $buttonBackground;
  color: $buttonText;
  font-family: $standardFont;
  font-weight: bold;
  text-align: center;
  padding: 0.5rem;
  padding-left: 1rem;
  padding-right: 1rem;
  font-size: 1.25rem;
  cursor: pointer;
  transition: all 0.3s linear;
  display: inline-block;
  text-decoration: none;

  &:hover {
    box-shadow: 1px 1px 6px 1px $brass;
    color: $yellow;
    @include outline($black, 0px, 2px);
  }
}

@mixin contactMethod {
  white-space: pre;
  padding-top: 3rem;

  @include responsive(desktopLarge) {
    padding-top: 0rem;
    flex-basis: 25%;
  }

  @include responsive(widescreen) {
    flex-basis: 20%;
  }
  @include responsive(desktopLargeSmaller) {
    flex-basis: 50%;
  }

  @include responsive(mobileSmall) {
    flex-basis: 100%;
  }
}

@mixin contactIcon {
  @include outline($white, 0px, 2px);
  transition: all 0.3s linear;
  display: block;
  margin: auto;
  font-size: 5rem;
  color: $themeBlue;
  flex-basis: 100%;
  position: absolute;
  bottom: 100%;
  // margin-top: -4.25rem;
}

@mixin contactLink {
  @include flex-center;
  text-decoration: none;
  color: $white;
  transition: all 0.3s linear;
  font-size: 1.5rem;
  margin: auto;
  text-align: center;
  position: relative;
  flex-wrap: wrap;
  // display: block;
  margin-top: 4rem;
}

@mixin title {
  @include outline($black, 1px, 3px);
  background-image: url("../Assets/Images/Backgrounds/shinglesDark.jpg");
  background-size: 15rem auto;
  padding: 1rem;
  padding-top: 2rem;
  padding-bottom: 2rem;
  color: $themeGreen;
  font-family: $titleFont;
  font-size: 5rem;
  font-weight: bold;
  text-align: center;
  letter-spacing: 4.5px;
  border-bottom: $titleBorder;

  @include responsive(mobile) {
    font-size: 4rem;
  }
}

@mixin subtitle {
  font-weight: bold;
}

@mixin standard-text {
  font-size: 1rem;
}

@mixin text-width {
  @include responsive(tablet) {
    padding-left: 1.5rem;
    padding-right: 1.5rem;
  }

  @include responsive(desktop) {
    padding-left: 10%;
    padding-right: 10%;
  }

  @include responsive(desktopLarge) {
    padding-left: 15%;
    padding-right: 15%;
  }
}
