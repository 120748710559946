@import "../../../Styles/master";

.covered {
  padding-top: 2rem;
  padding-bottom: 1rem;
  border-bottom: 4px solid $themeGreen;
  padding-right: 1rem;
  padding-left: 1rem;
  background-color: $black;
  color: $white;
  text-align: center;

  @include responsive(desktop) {
    padding-right: 10%;
    padding-left: 10%;
  }

  .covered-titleTop,
  .covered-titleBottom {
    font-size: 3rem;
    color: $themeGreen;
    padding-bottom: 1rem;
    text-align: center;
  }

  .covered-titleBottom {
    animation-delay: 2.25s;
  }

  .covered-animation {
    @include flex-center;
    padding-top: 2rem;
    padding-bottom: 2rem;
    width: 100%;
    overflow: hidden;

    .covered-image {
      object-fit: contain;
      width: 100%;
      height: auto;
      max-height: 10rem;
      display: block;
      padding-bottom: 0.5rem;
    }

    .covered-imageContainerRight,
    .covered-imageContainerLeft {
      white-space: pre;
      text-overflow: visible;
    }
    .covered-middleContainer {
      flex-basis: 45%;
    }

    .covered-imageContainerLeft {
      flex-basis: 20%;
    }

    .covered-imageContainerRight {
      flex-basis: 35%;
      animation-delay: 1.5s;
    }

    @include responsive(widescreen) {
      .covered-middleContainer {
        flex-basis: 50%;
      }

      .covered-imageContainerLeft {
        flex-basis: 20%;
      }

      .covered-imageContainerRight {
        flex-basis: 30%;
      }
    }

    @include responsive(tabletSmall) {
      .covered-middleContainer {
        flex-basis: 45%;
      }

      .covered-imageContainerLeft {
        flex-basis: 20%;
      }

      .covered-imageContainerRight {
        flex-basis: 35%;
      }
    }

    .covered-imageContainer {
      font-size: 1.5rem;
    }

    .covered-middleContainer {
      position: relative;
      box-sizing: border-box;
      padding-left: 2rem;
      padding-right: 2rem;

      @include responsive(tabletSmall) {
        padding-left: 1rem;
        padding-right: 1rem;
      }

      .covered-imageMiddle {
        opacity: 0;
        width: 100%;
        animation-name: grow-width;
        height: 100%;
        animation-fill-mode: forwards;
        animation-timing-function: ease-in-out;
        animation-duration: 0.75s;
        animation-delay: 0.75s;
        animation-duration: 1s;
      }
    }

    .covered-emblem {
      position: absolute;
      width: 15vw;
      height: 15vw;
      bottom: 1rem;
      right: calc(50% - 7.5vw);
      animation-delay: 0.75s;

      @include responsive(widescreen) {
        width: 15vw;
        height: 15vw;
      }

      @include responsive(tabletSmall) {
        width: 15vw;
        height: 15vw;
        bottom: 1rem;
      }
    }
  }
}
