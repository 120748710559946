@import "../../../Styles/master";

.map {
  height: 50vh;
  overflow: hidden;

  .map-frame {
    margin: 0;
    padding: 0;
    border: none;
    outline: none;
    width: 100%;
    height: calc(46px + 50vh);
    margin-top: -46px;
  }
}
