@import "../../../Styles/master";

.bam {
  flex-wrap: wrap;
  flex-basis: 100%;
  width: 100%;
  margin-top: 1rem;
  padding-top: 1rem;
  border-top: 4px solid $themeGreen;
  background-color: black;
  @include flex-center;

  .bam-container {
    padding-bottom: 0.5rem;
    padding-right: 0.5rem;
    cursor: pointer;

    @include flex-center;

    .bam-logo {
      height: 3rem;
      width: auto;
    }
  }
}
