$media: screen;

$mobileSmall: 350px;
$mobile: 450px;
$tabletSmall: 600px;
$tablet: 700px;
$desktopLarge: 900px;
$widescreen: 1200px;
$thiccc: 1500px;
$short: 500px;
$tall: 700px;
$topNavCutoff: 900px;
$stickyNavCutoff: 900px;

@mixin responsive($breakpoint) {
  @if $breakpoint == mobileSmall {
    @media #{$media} and ( max-width : $mobileSmall) {
      @content;
    }
  } @else if $breakpoint == mobile {
    @media #{$media} and ( max-width : $mobile) {
      @content;
    }
  } @else if $breakpoint == tabletSmall {
    @media #{$media} and ( max-width : $tabletSmall) {
      @content;
    }
  } @else if $breakpoint == tablet {
    @media #{$media} and ( max-width : $tablet) {
      @content;
    }
  } @else if $breakpoint == desktop {
    @media #{$media} and ( min-width : $tablet) {
      @content;
    }
  } @else if $breakpoint == desktopLargeSmaller {
    @media #{$media} and ( max-width : $desktopLarge) {
      @content;
    }
  } @else if $breakpoint == desktopLarge {
    @media #{$media} and ( min-width : $desktopLarge) {
      @content;
    }
  } @else if $breakpoint == widescreen {
    @media #{$media} and (min-width : $widescreen) {
      @content;
    }
  } @else if $breakpoint == widescreenSmaller {
    @media #{$media} and (max-width : $widescreen) {
      @content;
    }
  } @else if $breakpoint == thiccc {
    @media #{$media} and ( min-width : $thiccc) {
      @content;
    }
  } @else if $breakpoint == short {
    @media #{$media} and ( max-height : $short) {
      @content;
    }
  } @else if $breakpoint == tall {
    @media #{$media} and ( min-height : $tall) {
      @content;
    }
  } @else if $breakpoint == topNavCutoff {
    @media #{$media} and ( min-width : $topNavCutoff) {
      @content;
    }
  } @else if $breakpoint == stickyNavCutoff {
    @media #{$media} and ( min-width : $stickyNavCutoff) {
      @content;
    }
  }
}

$borderRadius: 8px;

// Nav
$link-height: 2.5rem;
$NavZ: 1000;

// Fonts
$paragraphFontSize: 1.5rem;
$subtitleFontSize: 1.75rem;
