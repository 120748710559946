@import "../../../Styles/master";

$screwAngle: 41deg;
$menuWidth: 3rem;
$menuHeight: 2.25rem;
$menuHeightNeg: -2.25rem;
$transitionSpeed: 0.4s;

@keyframes slideFade {
  0% {
    opacity: 1;
  }

  50% {
    opacity: 0.8;
  }

  100% {
    opacity: 0;
  }
}

.menu {
  @include inline-center;
  flex-wrap: wrap;
  position: relative;
  height: $menuHeight;
  width: $menuWidth;
  transition-timing-function: ease-in-out;
  padding-top: 0.25rem;
  padding-bottom: 0.25rem;

  &:hover {
    cursor: pointer;
  }

  .menu-icon {
    position: absolute;
    transition: opacity, transform;
    transition-duration: calc($transitionSpeed / 2), $transitionSpeed;
    width: $menuWidth;
    height: $menuHeight;
    flex-basis: 100%;
    font-size: 2.5rem;
    transition-timing-function: ease-in-out;
  }

  .menu-screwTop {
    transform-origin: left top;
  }

  .menu-screwBottom {
    transform-origin: left bottom;
  }

  .menu-screwTop,
  .menu-screwBottom {
    animation-name: slideFade;
    animation-duration: $transitionSpeed;
    animation-direction: reverse;
  }

  .menu-hammer {
    opacity: 0;
    height: auto;
  }

  &.menu-open {
    .menu-screwTop {
      transform: translateY(calc($menuHeight / 2));
      opacity: 0;
    }

    .menu-screwBottom {
      transform: translateY(calc($menuHeightNeg / 2));
      opacity: 0;
    }

    .menu-screwMiddle {
      opacity: 0;
    }

    .menu-screwTop,
    .menu-screwBottom {
      animation-name: slideFade;
      animation-duration: $transitionSpeed;
      animation-direction: forward;
    }

    .menu-hammer {
      opacity: 1;
    }

    .menu-hammerRight {
      transform: rotate(-$screwAngle);
    }

    .menu-hammerLeft {
      transform: rotate($screwAngle);
    }
  }
}
