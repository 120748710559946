@import "./Styles/master";

body,
html {
  width: 100%;
  overflow-x: hidden;
  margin: 0;
  padding: 0;
}

html {
  font-size: 18px;

  @include responsive(tablet) {
    font-size: 16px;
  }

  @include responsive(mobile) {
    font-size: 14px;
  }
}

body::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  background-color: $darkGray;
}

body::-webkit-scrollbar {
  width: 10px;
}

body::-webkit-scrollbar-thumb {
  border-radius: 10px;
  width: 10px;
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  background-color: $themeBlue;
  background-size: 100% 100%;
}
