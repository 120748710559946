@import "../../../Styles/master";

.footer {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  position: relative;
  overflow: hidden;
  background-color: $black;

  .footer-logoLink {
    border-top: 4px solid $themeGreen;
    flex-wrap: wrap;
    padding-bottom: 1rem;
    margin-bottom: 0.5rem;
    padding-top: 1rem;
    @include flex-center;
    @include full-width;

    .footer-logo {
      max-height: 6rem;
      max-width: 100%;
    }
  }
}

.footer-copy {
  display: inline-block;
  flex-basis: 100%;
  color: $white;
  font-family: $standardFont;
  font-size: 0.85rem;
  font-weight: bold;
  text-align: center;
  letter-spacing: 0.75px;
}
