@import "../../../Styles/master";
.pane-contactMethods {
  @include flex-center;
  align-items: stretch;
  flex-wrap: wrap;
  padding-bottom: 3rem;
  font-family: $paragraphFont;
  font-weight: bold;
  background-color: $black;
  color: $white;

  @include responsive(desktopLarge) {
    padding-top: 3rem;
  }

  .pane-contactMethod {
    @include contactMethod;

    &.pane-contactMethodHidden {
      visibility: hidden;
    }

    @for $i from 1 through 4 {
      &:nth-child(#{$i}) {
        animation-delay: ($i - 1) * 0.2s;
      }
    }

    .pane-contactInfo {
      flex-basis: 100%;
      text-align: center;
      padding-top: 0.25rem;
      letter-spacing: 0.5px;
    }

    .pane-contactLink {
      @include contactLink;

      &:hover {
        @include outline($yellow, 0px, 1px);
        .pane-contactIcon {
          @include outline($yellow, 0px, 3px);
          font-size: 6rem;
        }
      }

      .pane-contactIcon {
        @include contactIcon;

        &.pane-contactPhone {
          color: $themeGreen;
        }

        &.pane-contactEmail {
          color: $fadedRed;
          // transform: translateY(1rem);
        }
      }
    }
  }
}
