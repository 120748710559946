@import "../../../Styles/master";

#home {
  .home-header {
    display: flex;
    align-items: stretch;
    position: relative;
    font-family: $standardFont;
    border-bottom: 4px solid $themeGreen;
    background-image: url("../../../Assets/Images/Backgrounds/shinglesDark.jpg");
    background-size: 15rem auto;

    @include responsive(desktopLarge) {
      height: 80vh;
    }

    @include responsive(thiccc) {
      height: 85vh;
    }

    @include responsive(desktopLargeSmaller) {
      flex-wrap: wrap;
    }

    @include responsive(thiccc) {
      .home-headerImageContainer {
        flex-basis: 60% !important;
      }

      .home-headerInfo {
        flex-basis: 40% !important;
      }
    }

    .home-headerImageContainer,
    .home-headerInfo {
      flex-basis: 50%;
      position: relative;

      .home-headerTagline {
        display: block;
      }

      .home-headerTaglineMobile {
        display: none;
      }

      @include responsive(desktopLargeSmaller) {
        flex-basis: 100%;

        .home-headerTaglineMobile {
          display: block;
        }

        .home-headerTagline {
          display: none;
        }
      }
    }

    .home-headerTaglineMobile {
      position: absolute;
      bottom: 15%;
      z-index: 2;
    }

    .home-headerTaglineMobile,
    .home-headerTagline {
      @include outline($black, 2px, 3px);
      width: 100%;
      text-align: center;
      font-size: 2rem;
      font-weight: bold;
      color: $white;
      letter-spacing: 0.75px;

      @include responsive(widescreen) {
        font-size: 2.5rem;
      }

      .home-taglineItalics {
        display: block;
        font-size: 2.75rem;
        color: $imageText;

        @include responsive(widescreen) {
          font-size: 2.75rem;
        }
      }
    }

    .home-headerTagline {
      padding-bottom: 2.5rem;
    }

    .home-headerImageContainer {
      height: 25rem;
      overflow: hidden;

      @include responsive(desktopLarge) {
        height: 80vh;
      }

      @include responsive(thiccc) {
        height: 85vh;
      }
    }

    .home-headerImage {
      background-image: url("../../../Assets/Images/Homepage/background.jpg");
      background-repeat: no-repeat;
      background-size: cover;
      background-position: center;

      @include responsive(desktopLarge) {
        height: 100%;
        padding-top: 2rem;
        padding-bottom: 2rem;
        margin-top: -2rem;
        border-top-right-radius: 50%;
        border-bottom-right-radius: 50%;
        border-right: 5px ridge $themeGreen;
      }
      @include responsive(desktopLargeSmaller) {
        position: absolute;
        width: 100%;
        height: 25rem;
        padding-left: 10%;
        padding-right: 10%;
        margin-left: -10%;
        margin-top: -5px;
        border-bottom-right-radius: 50%;
        border-bottom-left-radius: 50%;
        border-bottom: 5px solid $themeGreen;
      }
    }

    .home-headerInfo {
      padding-bottom: 1rem;
      padding-top: 1rem;
      text-align: center;
      @include flex-center;

      .home-headerLogo {
        margin: auto;
        width: 90%;
        max-width: 30rem;
        display: block;

        @include responsive(desktopLargeSmaller) {
          width: 80%;
        }
      }

      .home-headerTenants {
        @include outline($black, 2px, 3px);
        margin: auto;
        text-align: center;
        font-weight: bold;
        font-size: 1.5rem;
        word-break: keep-all;
        white-space: nowrap;
        color: $themeGreen;
        padding-top: 2.5rem;
        padding-bottom: 2.5rem;

        @include responsive(widescreen) {
          font-size: $subtitleFontSize;
          padding-top: 2rem;
          padding-bottom: 2rem;
        }

        @include responsive(desktopLargeSmaller) {
          font-size: 2rem;
        }

        @include responsive(tabletSmall) {
          font-size: 1.5rem;
        }

        .home-headerTenant {
          padding: 0.5rem;
          padding-top: 0.25rem;
          padding-bottom: 0.25rem;
        }

        .home-headerTenantSeparator {
          width: 1.25rem;
          height: 1.25rem;
        }
      }

      .home-headerButton {
        @include button;
        margin: auto;
        line-height: 1.25rem;

        .home-headerButtonItalic {
          font-weight: normal;
          font-style: italic;
        }

        .home-headerButtonBold {
        }
      }
    }
  }

  .home-intro {
    @include flex-center;
    @include outline($black, 1px, 2px);
    border-bottom: 4px solid $themeGreen;
    flex-wrap: wrap;
    text-align: center;
    font-family: $paragraphFont;
    font-weight: bold;
    color: $themeGreen;
    letter-spacing: 0.45px;
    font-size: 2.5rem;
    padding: 1.5rem;
    padding-top: 3rem;
    padding-bottom: 3rem;

    @include responsive(desktopLargeSmaller) {
      .home-break {
        height: 1rem;
        display: block;
      }

      br {
        display: none;
      }
    }

    .home-introTagline {
      flex-basis: 100%;

      .home-introText {
        @include outline($black, 0px, 1px);
        color: $black;
        font-size: 1.5rem;
      }
    }
  }

  .home-contact {
    font-family: $paragraphFont;
    font-weight: bold;
    background-color: $black;
    color: $white;

    .home-contactTitle {
      @include outline($black, 0px, 1px);
      text-align: center;
      font-size: 3rem;
      color: $black;
      padding-top: 6rem;
      padding-bottom: 0.5rem;
      background-color: $themeGreen;

      @include responsive(tabletSmall) {
        font-size: 2.5rem;
      }

      .home-contactTitleText {
        @include flex-center;
        display: inline-flex;
        white-space: pre;
        position: relative;

        &.home-contactTitleTextHidden {
          visibility: hidden;
        }
      }

      .home-contactRoof {
        position: absolute;
        bottom: -0.75rem;
        width: 115%;
      }
    }
  }

  .home-mapText {
    background-color: $themeBlue;
    color: $white;
    font-family: $paragraphFont;
    font-weight: bold;
    padding: 2rem;
    text-align: center;
    font-size: 2.5rem;

    .home-mapSubtext {
      padding-top: 0.5rem;
      font-size: $subtitleFontSize;
    }

    @include responsive(tablet) {
      font-size: 2rem;

      .home-mapSubtext {
        font-size: 1.25rem;
      }
    }
  }
}
