@import "~react-image-gallery/styles/scss/image-gallery.scss";
@import "../../../Styles/master";

#gallery {
  .gallery-title {
    @include title;
  }

  .gallery-container {
    background-color: $darkGray;

    .image-gallery-swipe {
      @include flex-center;
      max-height: 120vw;
      height: 80vh;
      overflow: hidden;
    }

    .image-gallery-image {
      max-width: 100%;
      object-position: center;
      overflow: hidden;
      object-fit: contain;
      height: -webkit-fill-available;
      width: auto !important;
    }

    .image-gallery-thumbnails {
      background-image: url("../../../Assets/Images/Backgrounds/shinglesDark.jpg");
      background-size: 15rem auto;
      height: 15vh;

      .image-gallery-thumbnails-container {
        height: 100%;

        .image-gallery-thumbnail {
          width: 15vh;
          height: 100%;
          box-sizing: border-box;

          &.image-gallery-thumbnail.active,
          &:hover {
            border: 4px solid $themeBlue;
          }

          .image-gallery-thumbnail-inner {
            height: 100%;

            .image-gallery-thumbnail-image {
              object-fit: cover;
              height: 100%;
            }
          }
        }
      }
    }
  }
}
